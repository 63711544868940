import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import { importPrivateProfileActions } from '../../containers/private-profile-actions';
import ProtectedButton from '../../containers/protected-button';
import withAuth from '../../hoc/with-auth';
import withBlogMenuSettings from '../../hoc/with-blog-menu-settings';
import withExperiment from '../../hoc/with-experiment';
import withTranslate from '../../hoc/with-translate';
import DropdownButton from '../dropdown-button';
import HeaderAvatarImage from '../header-avatar-image';
import NotificationsLink from '../link/notifications-link';
import NotificationButton from '../notification-button';
import SearchButton from '../search-button';
import styles from './header-buttons.scss';

export const HeaderButtons = ({
  currentUser,
  showLoginButton,
  showSearch,
  isAuthenticated,
  t,
  isMobileMenuColorMatchDesktopExperimentEnabled,
  isLiveSiteEditorDisabled,
}) => (
  <div className={classNames(styles.container, 'header-buttons')}>
    {showSearch ? renderSearchButton() : null}
    {showLoginButton
      ? renderNotificationButton(
          isAuthenticated,
          NotificationsLink,
          isMobileMenuColorMatchDesktopExperimentEnabled,
        )
      : null}
    {showLoginButton && !isLiveSiteEditorDisabled
      ? renderAvatar(
          isAuthenticated,
          currentUser,
          t,
          isMobileMenuColorMatchDesktopExperimentEnabled,
        )
      : null}
  </div>
);

function renderSearchButton() {
  return (
    <div className={styles.searchButton}>
      <SearchButton />
    </div>
  );
}

function renderNotificationButton(
  isAuthenticated,
  NotificationsLink,
  isMobileMenuColorMatchDesktopExperimentEnabled,
) {
  const iconClassName = isMobileMenuColorMatchDesktopExperimentEnabled
    ? 'blog-navigation-container-fill'
    : 'blog-button-primary-icon-fill';
  const bubbleClassName = isMobileMenuColorMatchDesktopExperimentEnabled
    ? 'blog-header-notification-bubble-background-color blog-header-notification-bubble-text-color'
    : 'blog-icon-secondary-background-color';

  if (isAuthenticated) {
    return (
      <div className={styles.notificationButtonSpacing}>
        <NotificationButton
          component={NotificationsLink}
          iconClassName={iconClassName}
          bubbleClassName={bubbleClassName}
        />
      </div>
    );
  }
}

function renderAvatar(
  isAuthenticated,
  currentUser,
  t,
  isMobileMenuColorMatchDesktopExperimentEnabled,
) {
  if (isAuthenticated) {
    return (
      <DropdownButton
        actionsContainerClassName={styles.profileActions}
        position={DropdownButton.POSITION_RIGHT}
        icon={<HeaderAvatarImage user={currentUser} />}
      >
        {async () => {
          const PrivateProfileActions = await importPrivateProfileActions();
          return <PrivateProfileActions user={currentUser} />;
        }}
      </DropdownButton>
    );
  }

  const signUpClassName = isMobileMenuColorMatchDesktopExperimentEnabled
    ? classNames(
        styles.signUp,
        'blog-navigation-container-color',
        'header-buttons__sign-up',
      )
    : classNames(
        styles.signUp,
        'blog-button-primary-text-color',
        'header-buttons__sign-up',
      );

  return (
    <ProtectedButton tagName="span" className={signUpClassName}>
      {t('header-buttons.sign-up')}
    </ProtectedButton>
  );
}

HeaderButtons.propTypes = {
  currentUser: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  showSearch: PropTypes.bool,
  showLoginButton: PropTypes.bool,
  t: PropTypes.func,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
  isLiveSiteEditorDisabled: PropTypes.bool,
};

export default flowRight(
  withAuth,
  withBlogMenuSettings,
  withTranslate,
  withExperiment({
    isMobileMenuColorMatchDesktopExperimentEnabled:
      EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
    isLiveSiteEditorDisabled: EXPERIMENTS.LIVE_SITE_EDITOR_DEPRECATION,
  }),
)(HeaderButtons);
